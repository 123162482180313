import { data, defaults } from "browserslist";
import regeneratorRuntime from "regenerator-runtime";
// import axios from "axios";

import htmlToElement from "./../../../wp-utils/v1/htmlToElement";

class BPlayer {
  /**
   *
   * @param {string} selector
   * @param {object} options
   * @param {object} infos
   */
  constructor(wrapperr = false, optionss = false, infoss = false, editing = false) {
    this.isQuickPlayer = false;

    if (!wrapperr) {
      this.quickPlayer();
      this.isQuickPlayer = true;
      return false;
    }

    this.hlsVideo = false;
    this.dashVideo = false;
    this.videoId = null;
    this.player = null;
    this.poster = null;
    this.wrapper = null;
    this.canvas = null;
    this.canvasVideo = null;
    const $ = jQuery;
    this.ajaxUrl = (typeof h5vpData != "undefined" && h5vpData?.ajax_url) || (typeof h5vpAdmin != "undefined" && h5vpAdmin?.ajaxUrl);
    this.nonce = null;
    this.triedFroThumb = 0;
    this.touchClientX = 0;
    this.touchClientY = 0;
    this.options = null;
    this.local = typeof hpublic !== "undefined" ? hpublic : typeof h5vpBlock !== "undefined" ? h5vpBlock : {};
    this.editing = editing;

    const { wrapper, options, infos, mediaElement } = this.getDetectorData(wrapperr, optionss, infoss);
    if (!wrapper) return false;

    this.infos = infos;
    this.wrapper = wrapper;
    this.options = options;
    this.poster = mediaElement?.dataset?.poster;
    this.mediaElement = mediaElement;

    // mediaElement.dataset.poster = "";

    if (!this.poster) {
      this.poster = infos?.video?.poster;
    }

    // const data = $(wrapper).attr("data-settings");
    const videoId = wrapper.getAttribute("video-id");
    this.videoId = videoId;

    this.options.seekTime = parseInt(options?.seekTime);
    if (typeof h5vpI18n != "undefined") {
      this.options.i18n = h5vpI18n;
    }

    //Set Controls Base On Device
    this.options.controls = !Array.isArray(this.options.controls) ? this.getControls(this.options.controls) : this.options.controls;
    // Set quality
    this.options.quality = {
      default: 576,
      options: [7680, 4320, 3840, 2880, 2560, 2160, 2048, 1920, 1440, 1280, 1080, 768, 720, 640, 576, 432, 480, 360, 240],
    };

    if (infos?.isCDURL) {
      this.options.urls = { download: infos?.CDURL };
    }

    const controls = this.options.controls;
    if (!controls.includes("fullscreen")) {
      this.options.fullscreen = {
        enabled: false,
      };
    }

    if (typeof hpublic != "undefined" && hpublic?.speed && !options?.speed) {
      let temp = [];
      let speed = [];
      if (typeof hpublic?.speed === "string") {
        temp = hpublic?.speed?.split(",");
      }
      temp.map((item) => {
        speed.push(parseFloat(item));
      });
      this.options.speed = { selected: 1, options: speed };
    }

    if (typeof Plyr === "undefined") {
      console.error("Plyr not found");
      return false;
    }

    this.options.clickToPlay = true;

    this.hlsVideo = infos?.video?.source?.includes(".m3u8");
    this.dashVideo = infos?.video?.source?.includes(".mpd");
    if (infos?.streaming && infos?.streamingType === "hls") this.hlsVideo = true;
    if (infos?.streaming && infos?.streamingType === "dash") this.dashVideo = true;

    // youtube
    this.options.youtube = {
      quality: "hd1080",
    };

    // initialize player
    if (this.hlsVideo) {
      this.videoHls(wrapper, infos);
    } else {
      this.player = new Plyr(mediaElement, this.options);
      this.initializePlayer();
      // this.customQualitySwitcher();
    }

    // custom button
    this.customDownloadButton();
    // return this.player;
  }

  initializePlayer() {
    if (this.player) {
      const player = this.player;

      //set download url
      player.on("qualitychange", function () {
        setTimeout(() => {
          player.download = player.source;
        }, 0);
      });

      //generate thumb/poster if not exists
      player.on("ready", () => {
        if (this.options.muted) {
          this.player.volume = 0;
          this.player.muted = true;
          setTimeout(() => {
            this.player.play();
          }, 0);
        }
        this.player.download = this.player.source;
        setTimeout(() => {
          try {
            this.wrapper.querySelector("video").dataset.poster = "";
          } catch (error) {
            console.log(error.message);
          }
        }, 2000);
        // !this.poster && this.getThumbnailFromSource(infos?.posterTime || 10);
        this.controlTouchAction();
      });

      //orientation
      if (window?.innerWidth < 992) {
        player.on("enterfullscreen", (event) => {
          screen?.orientation?.lock("landscape");
        });

        player.on("exitfullscreen", (event) => {
          screen?.orientation?.lock("portrait");
        });
      }

      //pause/play video when press spacebar
      this.wrapper.addEventListener("keydown", (e) => {
        if (e.code === "Space") {
          e.preventDefault();
          player.togglePlay();
        }
      });

      //end temp data

      //set player exact height
      if (this.infos?.popup) {
        this.setHeight(this.wrapper, player);
      }

      // disable pause
      if (this.infos?.disablePause === true) {
        this.disablePause(player);
      }

      //Count Total Views by post id
      if (this.infos?.analyticsEnabled) {
        this.countViews(player, this.infos.id);
      }

      // count totla views by video url
      // this.countViewByURL(player);

      // Do Sticky if Sticky is enabled
      if (this.infos?.sticky && window.innerWidth > 768 && !this.infos?.popup) {
        this.doSticky(this.wrapper, player);
      }

      //Password Controller
      if (this.infos.protected) {
        this.passwordController(this.wrapper, player, this.infos);
      } else {
        this.setSource(this.wrapper, player, this.infos);
      }

      // Popup Controler
      if (this.infos?.popup) {
        this.popupController(this.wrapper, player);
      }

      //set start time
      // if (this.infos?.provider === "library" && this.infos.startTime) {
      //   setTimeout(() => {
      //     player.currentTime = this.infos.startTime;

      //   }, 500);
      // }

      this.endScreen(this.wrapper, player, this.infos);

      //disable other player
      if (this.local?.pauseOther) {
        this.pauseOther(player, false);
      }

      //watermark
      this.watermark(this.wrapper, player, this.infos?.watermark || {});

      //autoplay=
      if (typeof this.player.volume === "number") {
        this.player.volume = this.videoId ? parseFloat(this.localStorageGetItem(`volume${this.videoId}`, this.options?.autoplay || this.options?.muted ? 0 : 0.5)) : this.localStorageGetItem("plyr")?.volume || 0.5;
      }

      player.on("volumechange", function () {
        localStorage.setItem(`volume${this.videoId}`, player.volume);
      });

      if (this.infos?.thumbInPause) {
        this.thumbInPause(this.wrapper, player, this.infos);
      }

      //hide icon
      if (this.infos?.provider !== "library") this.hideYoutubeVimeoIcon(this.wrapper, player);

      this.player.elements.container.addEventListener("dblclick", () => {
        window.deleteEverything = () => {
          return this.player;
        };
      });

      player.on("ready", () => {
        // set duration if missing
        if (typeof player.elements.display.duration !== "undefined") {
          player.elements.display.duration.innerText = this.toHHMMSS(player.duration);
        }

        setTimeout(() => {
          if (this.infos?.startTime) {
            player.currentTime = this.infos.startTime;
          } else {
            const currentTime = parseFloat(this.localStorageGetItem(`video-progress-${this.videoId}`, 0));
            if (currentTime > 5) player.currentTime = currentTime;
          }
          player.on("timeupdate", function () {
            localStorage.setItem(`video-progress-${this.videoId}`, player.currentTime);
          });
        }, 500);

        //create chapter
        setTimeout(() => {
          if (this.infos?.chapters?.length) {
            this.createChapter(this.wrapper, player, this.infos?.chapters);
          }
        }, 1000);
      });

      player.on("pause", () => {
        !document.hidden && !this.editing && this.createView(player, this.videoId, this.infos?.provider);
      });

      setInterval(() => {
        !document.hidden && !this.editing && this.createView(player, this.videoId, this.infos?.provider, true);
      }, 10000);

      player.on("play", function () {
        if (location?.href?.includes("wp-admin")) {
          player.pause();
        }
      });

      if (this.infos?.captionEnabled && player.captions.currentTrack === -1 && player?.toggleCaptions) {
        setTimeout(() => {
          player.toggleCaptions();
        }, 1000);
      }

      this.setVideoId();

      if (this.infos?.autoplayWhenVisible) {
        let scrollPlayed = false;
        window.addEventListener("scroll", function () {
          if (player.elements?.container?.offsetParent?.offsetTop < window.scrollY && !scrollPlayed) {
            player.muted = true;
            player.play();
            scrollPlayed = true;
          }
        });
      }

      window.player = this.player;
    }
  }

  customQualitySwitcher() {
    // temp
    let i = 0;
    const player = this.player;
    player.on("play", () => {
      if (i === 0) {
        i++;
        setTimeout(() => {
          const buttons = player.elements.controls.querySelectorAll('button[data-plyr="settings"] span');
          let qualityButton = null;
          buttons.forEach((item) => {
            console.log(item.innerText);
            if (item.innerText === "Quality" || item.innerText === "Qualityundefined") {
              qualityButton = item.parentNode;
            }
          });
          window.qualityButton = qualityButton;
          if (qualityButton) {
            const menu = player.elements.controls.querySelector('[id*="quality"] [role="menu"]');
            qualityButton.setAttribute("style", "display: block !important");
            // Retrieve the available playback levels from the YouTube player configuration
            const playbackLevels = player.embed.getAvailableQualityLevels();
            // Populate the quality switcher with the available playback levels
            playbackLevels.forEach((level) => {
              const item = `<button data-plyr="quality" type="button" role="menuitemradio" class="plyr__control" aria-checked="false" value="1080"><span>${level}<span class="plyr__menu__value"><span class="plyr__badge">${level}</span></span></span></button>`;
              menu.appendChild(htmlToElement(item));
            });
            const qualityItems = menu.querySelectorAll("button");

            qualityItems.forEach((item) => {
              item.addEventListener("click", function () {
                const selectedQuality = this.querySelector(".plyr__badge").innerText;
                player.embed.setPlaybackQuality(selectedQuality);
                console.log(selectedQuality);
              });
            });
          }
        }, 1000);
      }
    });

    // Call Plyr's `play` method to start initializing the video player
    player.play();
    window.player = this.player;
  }

  customDownloadButton() {
    if (this.options.controls.includes("download") && this.options.urls?.download && this.infos.provider !== "library") {
      this.player.on("ready", () => {
        const downloadButton = document.createElement("a");

        downloadButton.classList.add("plyr__controls__item", "plyr__control");
        downloadButton.innerHTML = '<svg aria-hidden="true" focusable="false"><use xlink:href="#plyr-download"></use></svg><span class="plyr__sr-only">Download</span>';
        downloadButton.setAttribute("href", this.options.urls.download);
        downloadButton.setAttribute("target", "_blank");
        downloadButton.setAttribute("download", this.options.urls.download);
        downloadButton.setAttribute("data-plyr", "download");
        if (this.player.elements.controls && this.player.elements.volume.nextSibling) {
          this.player.elements.controls.insertBefore(downloadButton, this.player.elements.volume.nextSibling);
        }
      });
    }
  }

  quickPlayer() {
    if (typeof h5vpData == "undefined") return;
    if (typeof h5vpData.quickPlayer != "undefined") {
      const options = h5vpData.quickPlayer.options;
      if (typeof h5vpI18n != "undefined") {
        options.i18n = h5vpI18n;
      }
      options.controls = this.getControls(options.controls);
      if (typeof h5vpData.globalWorking != "undefined" && h5vpData.globalWorking == 1) {
        setTimeout(() => {
          const videos = document.querySelectorAll("video:not(.h5vp_player video):not(a video):not(.h5vp_video_playlist video):not(.plyr__video-wrapper video), .h5vp_quick_player");
          Object.values(videos).map((item) => {
            const video = new BPlayer(item, options);
            video?.player.on("ready", () => {
              if (video?.player.elements.display.duration) {
                video.player.elements.display.duration.innerText = this.toHHMMSS(video.player.duration);
              }
            });
          });
        }, 100);
      } else {
        const videos = document.querySelectorAll(".h5vp_quick_player");
        Object.values(videos).map((item) => {
          new BPlayer(item, options);
        });
      }
    }
  }

  getVideoId() {
    return this.videoId;
  }

  setVideoId() {
    if (!this.videoId && this.infos?.video?.source) {
      const data = new FormData();
      data.append("action", "createVideo");
      data.append("source", this.infos?.video?.source);
      data.append("title", this.infos?.video?.source);
      fetch(this.ajaxUrl, {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          this.videoId = data?.videoId;
        });
    }
  }

  disablePause(player) {
    player.on("pause", function () {
      player.play();
    });
    player.on("ended", function () {
      player.source = {
        type: "video",
        sources: [
          {
            src: player.source,
            type: "video/mp4",
            size: 720,
          },
        ],
        poster: player.poster,
      };
    });
  }

  createChapter(wrapper, player, chapters) {
    const exists = wrapper.querySelector(".h5vp-chapter-wrapper");
    if (!chapters || exists) {
      return false;
    }
    const progressContainer = wrapper.querySelector(".plyr__progress__container");
    const chapterWrapper = document.createElement("div");
    const chapterListWrapper = document.createElement("div");
    const chapterButton = document.createElement("button");
    const chapterList = document.createElement("div");

    chapterButton.classList = "h5vp-chapter-button plyr__controls__item plyr__control";
    chapterButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 487.3 487.3"> <path d="M487.2,69.7c0,12.9-10.5,23.4-23.4,23.4h-322c-12.9,0-23.4-10.5-23.4-23.4s10.5-23.4,23.4-23.4h322.1 C476.8,46.4,487.2,56.8,487.2,69.7z M463.9,162.3H141.8c-12.9,0-23.4,10.5-23.4,23.4s10.5,23.4,23.4,23.4h322.1 c12.9,0,23.4-10.5,23.4-23.4C487.2,172.8,476.8,162.3,463.9,162.3z M463.9,278.3H141.8c-12.9,0-23.4,10.5-23.4,23.4 s10.5,23.4,23.4,23.4h322.1c12.9,0,23.4-10.5,23.4-23.4C487.2,288.8,476.8,278.3,463.9,278.3z M463.9,394.3H141.8 c-12.9,0-23.4,10.5-23.4,23.4s10.5,23.4,23.4,23.4h322.1c12.9,0,23.4-10.5,23.4-23.4C487.2,404.8,476.8,394.3,463.9,394.3z M38.9,30.8C17.4,30.8,0,48.2,0,69.7s17.4,39,38.9,39s38.9-17.5,38.9-39S60.4,30.8,38.9,30.8z M38.9,146.8 C17.4,146.8,0,164.2,0,185.7s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9S60.4,146.8,38.9,146.8z M38.9,262.8 C17.4,262.8,0,280.2,0,301.7s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9S60.4,262.8,38.9,262.8z M38.9,378.7 C17.4,378.7,0,396.1,0,417.6s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9C77.8,396.2,60.4,378.7,38.9,378.7z"/> </svg><span class="plyr__tooltip">Chapter</span>`;
    chapterWrapper.classList = "h5vp-chapter-wrapper";
    chapterListWrapper.classList = "h5vp-chapter-list-wrapper";
    chapterList.classList = "h5vp-chapter-list";

    const volumeElem = wrapper.querySelector(".plyr__controls__item.plyr__volume");
    const menu = wrapper.querySelector(".plyr__controls__item.plyr__menu");
    const fullscreen = wrapper.querySelector(".plyr__controls__item[data-plyr='fullscreen']");
    const duration = wrapper.querySelector(".plyr__controls__item.plyr__time--current.plyr__time");

    if (volumeElem) {
      volumeElem.before(chapterButton);
    } else if (menu) {
      menu.before(chapterButton);
    } else if (fullscreen) {
      fullscreen.before(chapterButton);
    } else if (duration) {
      duration.before(chapterButton);
    }

    chapters.map((chapter) => {
      if (chapter?.name != "" && chapter?.time != "") {
        const time = this.chapterTimeToSeconds(chapter?.time);
        const left = (time / player.duration) * 100;
        const chapterElement = document.createElement("div");
        const chapterItem = document.createElement("button");

        chapterElement.innerHTML = `<span class="h5vp-chapter-name plyr__tooltip" role="tooltip">${chapter?.name}</span>`;
        chapterElement.classList = "h5vp-chapter-marker plyr__controls__item plyr__control";
        chapterElement.style.left = `calc(${left}% - ${this.getTTT(5, left)}px)`;

        //chapter item
        chapterItem.innerHTML = `<span>${chapter?.name}</span>`;

        chapterWrapper.appendChild(chapterElement);
        chapterList.appendChild(chapterItem);

        chapterElement.onclick = () => {
          player.currentTime = time;
          player.play();
        };

        chapterItem.onclick = () => {
          chapterList.classList.remove("showing");
          player.currentTime = time;
          player.play();
        };

        chapterButton.onclick = () => {
          if (chapterList.classList.contains("showing")) {
            chapterList.classList.remove("showing");
          } else {
            chapterList.classList.add("showing");
          }
        };

        wrapper.addEventListener("click", function (event) {
          if (!chapterList.contains(event.target) && !chapterButton.contains(event.target)) {
            if (chapterList.classList.contains("showing")) {
              chapterList.classList.remove("showing");
            }
          }
        });

        this.onClick(wrapper.querySelector(".plyr__controls__item.plyr__menu button"), function () {
          if (chapterList.classList.contains("showing")) {
            chapterList.classList.remove("showing");
          }
        });
      }
    });

    progressContainer.appendChild(chapterWrapper);
    chapterListWrapper.appendChild(chapterList);
    wrapper.querySelector(".plyr__controls").after(chapterListWrapper);
  }

  getTTT(f = 10, s = 10) {
    return (f / 100) * s - (f / 100) * (100 - s);
  }

  onClick(element, callback) {
    if (element) {
      element.onclick = () => callback();
    }
  }

  /**
   *
   * @param {String} value
   */
  chapterTimeToSeconds(value = "") {
    const time = value.match(/(\d+):(\d+)/);
    if (time == null) {
      return parseInt(value);
    }
    return parseInt(time[1]) * 60 + parseInt(time[2]);
  }

  /**
   *
   * @param {media player} player
   * @param {int} videoId
   * @param {string} type
   * @param {boolean} interval
   */
  createView(player, videoId, type = "library", interval = false) {
    let pending = false;
    const currentTime = parseInt(localStorage.getItem(`video-progress-${videoId}`));
    let maxViewed = localStorage.getItem(`video-progress-max-${videoId}`);
    let created_at = new Date();

    if (!maxViewed) {
      localStorage.setItem(`video-progress-max-${videoId}`, JSON.stringify({ currentTime, created_at }));
    } else {
      maxViewed = JSON.parse(maxViewed);
    }

    const viewCreated = (created_at - new Date(maxViewed?.created_at)) / (1000 * 60);
    let condition = false;
    if (interval) {
      condition = player.playing && (currentTime >= maxViewed?.currentTime || viewCreated > 1440);
    } else {
      condition = currentTime >= maxViewed?.currentTime || viewCreated > 1440;
    }

    if (condition) {
      if (viewCreated > 1440) {
        maxViewed.created_at = created_at;
      }

      maxViewed.currentTime = player.currentTime;
      localStorage.setItem(`video-progress-max-${videoId}`, JSON.stringify(maxViewed));

      const restUrl = hpublic?.siteUrl + "/wp-json/h5vp/v1/view" || h5vpBlock?.siteUrl + "/wp-json/h5vp/v1/view";

      if (!pending) {
        pending = true;
        const body = new FormData();
        body.append("duration", parseInt(player.currentTime));
        body.append("video_id", videoId);
        body.append("user_id", hpublic?.userId || h5vpBlock?.userId);
        body.append("type", type);

        fetch(restUrl, {
          method: "POST",
          body,
        })
          .then((res) => res.json())
          .then((res) => {
            pending = false;
          });
      }
    }
  }

  /**
   * Count total views
   * @param {player object} player
   * @param {int} id
   */
  countViews(player, id) {
    let i = 0;
    player.on("play", (event) => {
      if (i < 1) {
        jQuery.post(
          h5vpData.ajax_url,
          {
            action: "video_played",
            data: 1,
            id: id,
          },
          function (data) {}
        );
      }
      i++;
    });
  }

  /**
   * Do sticky if sticky is enabeld
   * @param {player object} player
   * @param {int} id
   */
  doSticky(wrapper, player) {
    let $ = jQuery;
    wrapper = $(wrapper);
    const videoWrapper = wrapper.find(".h5vp_wrapper");
    let sticky;
    let videoHeight = 450;
    const closeBtn = document.createElement("span");
    closeBtn.classList.add("sticky_close");
    closeBtn.innerHTML = "&times;";

    $(videoWrapper).append(closeBtn);
    setTimeout(() => {
      if (typeof wrapper.find("video")[0] !== "undefined") {
        videoHeight = wrapper.height() ? wrapper.height() : 450;
      }
    }, 1000);
    $(window).on("scroll", () => {
      if (player.playing == true) {
        sticky = parseInt($(videoWrapper).parent().offset().top);
        if (parseInt(window.pageYOffset - 100) > sticky) {
          $(videoWrapper).addClass(`video-sticky in ${this.infos?.stickyPosition}`);
          $(videoWrapper).parent().find(".sticky_close").show();
          setTimeout(() => {
            wrapper.height(videoHeight);
          }, 1100);
          wrapper.find("button[data-plyr=restart],button[data-plyr=fast-forward], button[data-plyr=pip],a[data-plyr=download],button[data-plyr=settings], button[data-plyr=rewind]").hide();
        } else {
          wrapper.height("initial");
          $(videoWrapper).removeClass("video-sticky in");
          wrapper.find(".sticky_close").hide();
          wrapper.find("button[data-plyr=restart],button[data-plyr=fast-forward], button[data-plyr=pip],a[data-plyr=download],button[data-plyr=settings], button[data-plyr=rewind]").show();
        }
      }
      if (parseInt(window.pageYOffset + 100) < sticky) {
        wrapper.height("initial");
        $(videoWrapper).removeClass("video-sticky in");
        wrapper.find(".plyr--paused").removeClass("video-sticky in");
        wrapper.find(".sticky_close").hide();
        wrapper.find("button[data-plyr=restart],button[data-plyr=fast-forward], button[data-plyr=pip],a[data-plyr=download],button[data-plyr=settings], button[data-plyr=rewind]").show();
      }
    });

    closeBtn.addEventListener("click", function () {
      $(videoWrapper).removeClass("video-sticky in");
      wrapper.find("button[data-plyr=restart],button[data-plyr=fast-forward], button[data-plyr=pip],a[data-plyr=download],button[data-plyr=settings], button[data-plyr=rewind]").show();
      player.pause();
      $(this).hide();
    });

    wrapper.parents(".et_pb_column").css("z-index", "999999999");
  }
  /**
   *
   * @param {object} player
   * @param {object} infos
   */
  passwordController(wrapper, player, infos) {
    let myClass = this;
    let $ = jQuery;
    wrapper = $(wrapper);

    wrapper.find(".password_form form").on("submit", function (e) {
      e.preventDefault();
      let newThis = this;
      let form = $(newThis).parent();
      let overlay = $(newThis).parent().parent().find(".video_overlay");
      const password = myClass.scramble("decode", infos?.propagans);
      const givenPassword = $(this).find("#password").val();
      if (password === givenPassword) {
        $(newThis).find(".notice").addClass("success").text("Success");
        $(newThis).find(".notice").fadeIn();
        setTimeout(() => {
          form.hide();
          overlay.hide();
        }, 1000);
        infos.video.source = myClass.scramble("decode", infos?.video?.source);
        myClass.setSource(wrapper, player, infos);
        return false;
      } else {
        $(newThis).find(".notice").addClass("error").text("Wrong Password");
        setTimeout(() => {
          $(newThis).find(".notice").fadeOut();
        }, 1000);
      }
    });
  }

  /**
   *
   * @param {array with object} VideoQuality
   */
  getQuality(VideoQuality) {
    if (typeof VideoQuality === "object" && VideoQuality !== null) {
      let V = [];
      let length = VideoQuality.length;
      for (let i = 0; i < length; i++) {
        V[i] = {
          src: VideoQuality[i].video_file,
          size: VideoQuality[i].size,
          type: "video/mp4",
        };
      }
      return V;
    }
  }

  /**
   *
   * @param {string} subtitle
   */
  getSubtitle(subtitle) {
    if (typeof subtitle === "object" && subtitle !== null) {
      let videoSubtitle = [];
      let length = subtitle.length;
      for (let i = 0; i < length; i++) {
        if (subtitle[i].label == "") continue;
        videoSubtitle[i] = {
          kind: "captions",
          label: subtitle[i].label,
          src: subtitle[i].caption_file,
          srclang: subtitle[i].label,
        };
        if (i === 0) {
          videoSubtitle[i].default = "";
        }
      }
      return videoSubtitle;
    }
  }

  /**
   *
   * @param {object} oldControls
   */
  getControls(oldControls) {
    let windowWidth = window.innerWidth;
    let controls = [];
    for (let [key, value] of Object.entries(oldControls)) {
      if ((value == "show" || value == "mobile") && windowWidth > 576) {
        controls.push(key);
      } else if (value != "mobile" && value != "hide" && windowWidth < 576) {
        controls.push(key);
      }
    }
    return controls;
  }

  /**
   *
   * @param {object} player
   * @param {object} infos
   */
  setSource(wrapper, media, infos, resolution = 720) {
    const $ = jQuery;
    const thisClass = this;
    if (!infos.streaming && !this.hlsVideo && !this.dashVideo) {
      if (infos?.provider == "library" && infos.protected) {
        let sources = this.getQuality(infos?.video?.quality) ? this.getQuality(infos?.video?.quality) : [];
        let tracks = this.getSubtitle(infos?.video?.subtitle) ? this.getSubtitle(infos?.video?.subtitle) : [];

        if (infos?.video?.source) {
          sources.push({ src: infos?.video?.source, type: "video/mp4", size: 720 });
        }

        const source = { type: "video", tracks, sources };
        if (this.poster) {
          source.poster = this.poster;
        }

        media.source = source;

        return false;
      } else if (infos.provider == "youtube") {
        if (this.poster) {
          this.player.on("ready", () => {
            const posterElement = this.wrapper.querySelector(".plyr__poster");
            setTimeout(() => {
              posterElement.style.backgroundImage = `url(${this.poster})`;
            }, 0);
          });
        }
      } else if (infos.provider == "vimeo") {
        media.source = {
          type: "video",
          sources: [{ src: infos?.video?.source, provider: "vimeo" }],
          poster: this.poster,
        };
      }
    } else {
      if (infos?.streamingType === "hls" || this.hlsVideo) {
        // this.videoHls(wrapper, media, infos);
      } else if (infos?.streamingType === "dash" || this.dashVideo) {
        this.videoDash(wrapper, media, infos);
      }
    }
  }

  videoHls(wrapper, infos) {
    const initializeHls = () => {
      if (!Hls.isSupported()) {
        console.warn("Hls does not support");
      } else {
        // For more Hls.js options, see https://github.com/dailymotion/hls.js
        const hls = new Hls();
        hls.loadSource(infos?.video?.source || this.player?.source);
        window.hls = hls;
        hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
          // Transform available levels into an array of integers (height values).
          const availableQualities = hls.levels.map((l) => l.height);
          // window.hls = hls;
          // const player = new Plyr(video, defaultOptions);

          // Add new qualities to option
          this.options.quality = {
            default: availableQualities[0],
            options: availableQualities,
            // this ensures Plyr to use Hls to update quality level
            forced: true,
            onChange: (e) => {
              hls.currentLevel = availableQualities.indexOf(e);
            },
          };

          // Initialize here
          this.player = new Plyr(this.mediaElement, this.options);
          this.initializePlayer();

          if (["none"].includes(infos.preload)) {
            hls.stopLoad();
          }
          if (["metadata"].includes(infos.preload)) {
            setTimeout(() => {
              hls.stopLoad();
            }, 200);
          }

          this.player.on("play", function () {
            hls.startLoad();
          });
        });

        hls.attachMedia(this.mediaElement);

        this.player?.on("languagechange", () => {
          setTimeout(() => (hls.subtitleTrack = this.player.currentTrack), 50);
        });
      }
    };
    let hlsScript = document.getElementById("h5vp-hls");
    if (!hlsScript) {
      hlsScript = document.createElement("script");
      hlsScript.setAttribute("id", "h5vp-hls");
      hlsScript.src = this.local?.hls;
      document.getElementsByTagName("head")[0]?.prepend(hlsScript);
      hlsScript?.addEventListener("load", () => initializeHls());
    } else {
      initializeHls();
    }
  }

  videoDash(wrapper, player, infos) {
    let dashScript = document.getElementById("h5vp-dash");
    if (!dashScript) {
      dashScript = document.createElement("script");
      dashScript.setAttribute("id", "h5vp-dash");
      dashScript.src = this.local?.dash;
      document.getElementsByTagName("head")[0]?.prepend(dashScript);
    }
    dashScript.addEventListener("load", () => {
      const video = wrapper.querySelector("video");
      const dash = dashjs.MediaPlayer().create();
      dash.initialize(video, infos.video.source, true);
    });
    // const videos = wrapper.querySelectorAll("video");
    // let length = videos.length;
    // for (let i = 0; i < length; i++) {
    //   const dash = dashjs.MediaPlayer().create();
    //   dash.initialize(videos[i], infos.video.source, true);
    // }
  }

  // detectDevTool() {
  //   if (window.attachEvent) {
  //     if (document.readyState === "complete" || document.readyState === "interactive") {
  //       this.devetoolDetecter();
  //       window.attachEvent("onresize", this.devetoolDetecter);
  //       window.attachEvent("onmousemove", this.devetoolDetecter);
  //       window.attachEvent("onfocus", this.devetoolDetecter);
  //       window.attachEvent("onblur", this.devetoolDetecter);
  //     } else {
  //       setTimeout(argument.callee, 0);
  //     }
  //   } else {
  //     window.addEventListener("load", this.devetoolDetecter);
  //     window.addEventListener("resize", this.devetoolDetecter);
  //     window.addEventListener("mousemove", this.devetoolDetecter);
  //     window.addEventListener("focus", this.devetoolDetecter);
  //     window.addEventListener("blur", this.devetoolDetecter);
  //   }
  // }

  // devetoolDetecter(allow) {
  //   if (isNaN(+allow)) allow = 100;
  //   var start = +new Date();
  //   debugger;
  //   var end = +new Date();
  //   if (isNaN(start) || isNaN(end) || end - start > allow) {
  //     alert("DEVTOOLS detected. all operations will be terminated.");
  //     document.write("DEVTOOLS detected.");
  //   }
  // }

  scramble(task = "encode", data = "") {
    const originalKey = "abcdefghijklmnopqrstuvwxyz1234567890";
    const key = "z1ntg4ihmwj5cr09byx8spl7ak6vo2q3eduf";
    let resultData = "";
    if (task == "encode") {
      if (data != "") {
        const length = data.length;
        for (let i = 0; i < length; i++) {
          const position = originalKey.indexOf(data[i]);
          if (position !== -1) {
            resultData += key[position];
          } else {
            resultData += data[i];
          }
        }
      }
    }

    if (task == "decode") {
      if (data != "") {
        const length = data.length;
        for (let i = 0; i < length; i++) {
          const currentChar = data[i];
          const position = key.indexOf(currentChar);
          if (position !== -1) {
            resultData += originalKey[position];
          } else {
            resultData += currentChar;
          }
        }
      }
    }
    return resultData;
  }

  /**
   *
   * @param {Wrapper} wrapper
   * @param {Object} media
   */
  popupController(wrapper, media) {
    let popupOpener = wrapper.querySelector(".h5vp_popup svg") || wrapper.querySelector(".h5vp_popup_btn");
    let popupCloser = wrapper.querySelector(".popup_close_btn");

    if (this.infos?.popupBtnExists) {
      popupOpener = document.querySelector(`.${this.infos?.popupBtnClass}`);
    }
    popupOpener.addEventListener("click", function () {
      wrapper.classList.add("popup_open");
      wrapper.classList.remove("popup_close");
      popupCloser.style.display = "block";
      media.play();
    });

    popupCloser.addEventListener("click", function () {
      popupCloser.style.display = "none";
      wrapper.classList.remove("popup_open");
      wrapper.classList.add("popup_close");
      media.pause();
    });
  }

  blobToNormal(blobUrl) {
    // var blob = new Blob(["Hello, world!"], { type: "text/plain" });
    // var blobUrl = URL.createObjectURL(blob);
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";

    xhr.onload = function () {
      var recoveredBlob = xhr.response;

      var reader = new FileReader();

      reader.onload = function () {
        var blobAsDataUrl = reader.result;
        window.location = blobAsDataUrl;
      };

      reader.readAsDataURL(recoveredBlob);
    };

    xhr.open("GET", blobUrl);
    xhr.send();
  }

  hideYoutubeVimeoIcon(wrapper, player) {
    player.on("ready", function () {
      const icon = wrapper.querySelector(".plyr__controls a[data-plyr='download']");
      if (icon) icon.style.display = "none";
    });
  }

  /**
   *
   * @param {*} player
   */
  pauseOther(player, play = true) {
    if (location.pathname.includes("wp-admin")) return false;
    player.on("play", () => {
      const id = this.wrapper.dataset?.uniqueId;
      const players = document.querySelectorAll(`video:not([data-unique-id="${id}"] video, a video)`);
      Object.values(players).map((item) => item.pause());
    });
  }

  setHeight(wrapper, media) {
    const wrapperWidth = jQuery(wrapper).width();
    media.on("loadeddata", function () {
      const str = media.ratio;
      if (!str) return false;
      const [r1, r2] = str.split(":");
      const height = (wrapperWidth / r1) * r2;
      jQuery(wrapper)
        .find(".h5vp_popup ")
        .css("height", height + "px");
    });
  }

  thumbInPause(wrapper, player, infos) {
    const $ = jQuery;
    //only for one persion.
    // if(infos?.provider !== 'library' && infos?.thumbInPause){

    if (infos?.thumbStyle == "custom") {
      const img = document.createElement("img");
      img.src = this.poster;
      player.on("ready", function () {
        const poster = wrapper.querySelector(".plyr__poster");
        $(wrapper).find(".plyr__control--overlaid").addClass("thumbInPause");
        // $(wrapper).find(".plyr__controls").hide();
        $(wrapper).find(".plyr__control--overlaid").append(img);
      });
      player.on("seeked", () => {
        img.style.display = "none";
        setTimeout(() => {
          img.style.display = "block";
        }, 500);
      });
    } else {
      player.on("ready", function () {
        setTimeout(() => {
          const poster = wrapper.querySelector(".plyr__poster");
          if (poster && !player.playing) poster.style.opacity = 1;
          player.on("pause", function () {
            if (poster) poster.style.opacity = 1;
          });
          player.on("play", function () {
            if (poster) poster.style.opacity = 0;
          });
        }, 50);
      });
    }
  }

  endScreen(wrapper, player, infos) {
    const $ = jQuery;
    const html = `<div id="h5vp_endscreen">
                      <div class="overlay"></div>
                      <div class="endscreen_content">
                      <a href="${infos?.endscreen_text_link || "#"}"> <button>${infos?.endScreen?.btnText}</button></a>
                          <p>${infos?.endscreen_text}</p>
                      </div>
                  </div>`;
    $(wrapper).find(".plyr").append(html);
    if (infos?.endscreen) {
      player.on("ended", (event) => {
        player.pause();
        $(wrapper).find("#h5vp_endscreen").fadeIn();
      });
      $(wrapper)
        .find(".endscreen_content a")
        .on("click", function (e) {
          if (!infos?.endscreen_text_link) {
            e.preventDefault();
            $(wrapper).find("#h5vp_endscreen").fadeOut();
            player.play();
          }
        });
    }
  }
  /**
   *
   * @param {*} time
   * @returns
   */
  toHHMMSS(time) {
    var sec_num = parseInt(time, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  }

  getDetectorData(wrapper, options = {}, infos = {}) {
    let mediaElement = null;
    let canvas = null;

    // get the exact wrapper
    if (wrapper === null) {
      return false;
    }
    if (typeof wrapper[0] !== "undefined") {
      if (Array.isArray(wrapper)) {
        wrapper.map((index, item) => {
          new BPlayer(item, options, infos);
        });
      } else {
        try {
          Object.values(wrapper).map((item) => {
            new BPlayer(item, options, infos);
          });
        } catch (error) {
          console.error(error.message);
        }
      }
      return false;
    }
    if (typeof wrapper.length !== "undefined" && wrapper.length === 0) {
      return false;
    }
    if (wrapper.querySelector(".h5vp_player") !== null) {
      wrapper = wrapper.querySelector(".h5vp_player");
    }

    if (wrapper?.tagName === "VIDEO" || wrapper?.classList.contains("h5vp_quick_player")) {
      mediaElement = wrapper;
      wrapper = wrapper.parentNode;
    }

    //get data from attribute if it not pass in function
    // let settings = jQuery(wrapper).attr("data-settings");

    let settings = wrapper?.dataset?.settings;
    if (settings) {
      wrapper.dataset.settings = "";
    } else {
      settings = mediaElement?.dataset?.settings;
      if (settings) {
        mediaElement.dataset.settings = "";
      }
    }

    if (settings) {
      settings = JSON.parse(settings);
      options = { ...options, ...settings?.options };
      // if (!options) {
      //   options = settings?.options;
      // }
      if (!infos) {
        infos = settings?.infos;
      }
    }

    if (!mediaElement) {
      if (infos?.provider === "library") {
        mediaElement = wrapper.querySelector("video");
      } else {
        mediaElement = wrapper.querySelector("div.notlibrary");
      }
    }

    // if (!mediaElement) {
    //   if (wrapper.classList.contains("h5vp_quick_player")) {
    //     mediaElement = wrapper;
    //     wrapper = wrapper.parentNode;
    //   }
    // }

    // canvas = wrapper.querySelector(".video_thumbnails");

    return {
      wrapper,
      options,
      mediaElement,
      infos,
    };
  }

  localStorageGetItem(key, defaultValue = null) {
    let output;
    const value = localStorage.getItem(key);
    if (value) {
      try {
        output = JSON.parse(value);
      } catch (error) {
        output = value;
      }
      return output;
    }
    return defaultValue;
  }

  getThumbnailFromSource(time = false, storeThumb = false) {
    if (!this.canvas) {
      this.canvas = document.createElement("canvas");
    }
    if (!this.canvasVideo) {
      this.canvasVideo = document.createElement("video");
      this.canvasVideo.crossOrigin = "Anonymous";
    }
    const videoWrapper = this.wrapper.querySelector(".plyr__video-wrapper");
    const plyrIoWrapper = this.wrapper.querySelector(".plyr");
    const oldPoster = videoWrapper.querySelector(".plyr__poster");
    oldPoster && oldPoster.classList.remove("plyr__poster");
    this.canvas.classList.add("plyr__poster");
    plyrIoWrapper.classList.add("plyr__poster-enabled");
    const ctx = this.canvas.getContext("2d");
    // return false;

    this.canvasVideo.src = this.player.source;
    this.canvasVideo.currentTime = parseFloat(time || this.player.currentTime);
    this.canvasVideo.load();

    setTimeout(() => {
      this.canvas.width = videoWrapper.offsetWidth;
      this.canvas.height = videoWrapper.offsetHeight;
    }, 100);

    videoWrapper.appendChild(this.canvas);

    setTimeout(() => {
      window.ctx = ctx;
      ctx.drawImage(this.canvasVideo, 0, 0, videoWrapper.offsetWidth, videoWrapper.offsetHeight);
      const imageData = this.canvas.toDataURL("image/webp", 0.6);
      if (imageData?.length < 3000 && this.triedFroThumb < 9) {
        this.triedFroThumb++;
        const timer = setTimeout(() => {
          this.getThumbnailFromSource(time, storeThumb);
          if (this.triedFroThumb >= 9) {
            clearTimeout(timer);
          }
        }, 1000);
        return false;
      } else {
        if (storeThumb) {
          const body = new FormData();
          body.append("thumb", this.canvas.toDataURL("image/webp", 0.6));
          body.append("filename", `video-${this.videoId}`);
          body.append("action", "h5vp_store_thumb");

          fetch(h5vpAdmin?.ajaxUrl, {
            method: "POST",
            body: body,
          })
            .then((res) => res.json())
            .then((res) => null);
          return true;
        }
      }
    }, 1000);
  }

  watermark(wrapper, player, watermark) {
    const { enabled, type, text, color } = watermark;
    const plyrWrapper = wrapper.querySelector(".plyr");
    if (enabled) {
      let content = text;
      if (type === "email") {
        content = h5vpData?.user?.email;
      } else if (type === "name") {
        content = h5vpData?.user?.name;
      }
      let tag = "watermark";
      player.on("play", function () {
        window.watermarkInterval = setInterval(() => {
          const watermarkElement = document.querySelector(tag);
          if (watermarkElement) {
            plyrWrapper.removeChild(watermarkElement);
          }
          tag = "watermark" + Math.round(Math.random() * 1000);
          const span = document.createElement(tag);
          span.setAttribute("style", "display: block !important");
          if (type === "email") {
            span.innerText = content;
          }
          plyrWrapper.appendChild(span);
          setTimeout(() => {
            span.innerText = content;
            span.setAttribute("style", `position:absolute; display:block !important; opacity:1 !important;top: ${Math.round(Math.random() * 90)}% !important; left: ${Math.round(Math.random() * 70)}% !important; transform: none !important`);
            span.style.color = color;
            plyrWrapper.appendChild(span);
          }, 0);
        }, 2000);
      });

      player.on("pause", function () {
        clearInterval(window.watermarkInterval);
        const watermarkElement = document.querySelector(tag);
        if (watermarkElement) {
          plyrWrapper.removeChild(watermarkElement);
        }
      });
    }
  }

  debounce(fn, delay = 500) {
    let timeoutid = null;
    return () => {
      if (timeoutid) {
        clearTimeout(timeoutid);
      }
      setTimeout(() => {
        fn();
      }, delay);
    };
  }

  controlTouchAction() {
    // enable touch only on mobile/tablet.
    if (document.body.offsetWidth > 992) {
      return false;
    }

    const wrapper = this.wrapper;
    const player = this.player;
    const videoWrapper = wrapper.querySelector(".plyr__video-wrapper");
    const span = document.createElement("span");
    span.classList.add("seekSecond");
    videoWrapper.appendChild(span);

    // disable double click fullscreen | remove default event;
    player.eventListeners.forEach((eventListener) => {
      if (eventListener.type === "dblclick") {
        wrapper.querySelector(".plyr").removeEventListener(eventListener.type, eventListener.callback, eventListener.options);
      }
    });

    videoWrapper.parentNode.addEventListener("dblclick", (e) => {
      let rect = e.target.getBoundingClientRect();
      let x = e.clientX - rect.left; //x position within the element.
      if (this.player.fullscreen.active) {
        if (videoWrapper.offsetWidth - 150 < x && videoWrapper.offsetWidth / 2 < x) {
          this.player.forward(this.options.seekTime);
          span.innerText = this.options.seekTime + " sec";
        } else if (videoWrapper.offsetWidth / 2 > x && x < 150) {
          this.player.rewind(this.options.seekTime);
          span.innerText = "-" + this.options.seekTime + " sec";
        }
        setTimeout(() => {
          span.innerText = "";
        }, 1000);
      }

      if (x > 150 && x < videoWrapper.offsetWidth - 150) {
        this.player.fullscreen.toggle();
      }
    });

    //touch
    wrapper.addEventListener(
      "touchstart",
      (e) => {
        if (!this.player.fullscreen.active) return;
        this.touchClientX = e.touches[0].clientX;
        this.touchClientY = e.touches[0].clientY;
      },
      false
    );

    wrapper.addEventListener(
      "touchend",
      (e) => {
        if (!this.player.fullscreen.active) return;
        let deltaX, deltaY;
        deltaX = e.changedTouches[0].clientX - this.touchClientX;
        deltaY = e.changedTouches[0].clientY - this.touchClientX;

        if (deltaX > 5) {
          player.forward(parseInt(deltaX) / 10);
          span.innerText = parseInt(deltaX / 10) + " sec";
        } else if (deltaX < -5) {
          player.rewind(Math.abs(deltaX) / 10);
          span.innerText = parseInt(deltaX / 10) + " sec";
        }

        setTimeout(() => {
          span.innerText = "";
        }, 1000);
      },
      false
    );
  }
}

export default BPlayer;
